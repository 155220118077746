<app-modal
  *transloco="let t; read: 'shared'"
  [canBeClosed]="false"
  [shouldShowCancelButton]="false"
  [version]="'2.0.0'"
>
  <app-modal-title>
    {{ t('reloadThisPageModalTitle') }}
  </app-modal-title>

  <app-modal-content>
    {{ t('reloadThisPageModalContent') }}
  </app-modal-content>

  <app-modal-actions>
    <app-button
      [backgroundColor]="'secondary'"
      [width]="'auto'"
      (click)="onReloadButtonClick()"
      appModalAction
    >
      {{ t('reload') }}
    </app-button>
  </app-modal-actions>
</app-modal>
